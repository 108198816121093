<template>
  <div
    class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8 bg-blue"
  >
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        class="mx-auto h-20 w-auto"
        src="/logo-short.svg"
        alt="Váš Pověřenec"
      />
    </div>

    <div class="sm:mt-6 lg:mt-8 sm:mx-auto sm:w-full sm:max-w-[480px]">
      <div class="bg-white px-6 py-6 shadow sm:rounded-lg sm:px-12">
        <h2 class="text-xl font-bold text-center text-gray-900">
          {{ $t("pages.signin.login") }}
        </h2>
        <form class="space-y-6" @submit.prevent="signin()">
          <!-- Email field -->
          <div>
            <label
              for="email"
              class="block text-sm font-medium leading-6 text-gray-900"
              >{{ $t("pages.signin.email") }}</label
            >
            <div class="relative w-full max-w-sm items-center">
              <Input
                v-model="email"
                type="email"
                autocomplete="email"
                required
              />
            </div>
          </div>

          <!-- Password field -->
          <div>
            <label
              for="password"
              class="block text-sm font-medium leading-6 text-gray-900"
              >{{ $t("pages.signin.password") }}</label
            >
            <div class="relative w-full max-w-sm items-center">
              <Input
                id="password"
                v-model="password"
                :type="isPasswordVisible ? 'text' : 'password'"
                required
                class="pl-10 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <span
                class="absolute start-0 inset-y-0 flex items-center justify-center px-2"
              >
                <MagnifyingGlassIcon class="size-6 text-muted-foreground" />
              </span>
            </div>
          </div>

          <!-- Submit button -->
          <div>
            <Button
              :disabled="disabledButton || loading"
              type="submit"
              @click.prevent="signin()"
              class="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              :class="{
                'opacity-50 cursor-not-allowed': disabledButton || loading,
              }"
            >
              <Loader2 v-if="loading" class="mr-2 animate-spin" />
              {{ $t("pages.signin.signIn") }}
            </Button>
          </div>
        </form>
      </div>

      <p class="mt-10 text-center text-sm text-gray-500">
        <NuxtLink
          to="/auth/forgot-password"
          class="font-semibold leading-6 text-indigo hover:text-indigo-500"
          >{{ $t("pages.signin.forgotPassword") }}</NuxtLink
        >
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Loader2 } from "lucide-vue-next";
import { MagnifyingGlassIcon } from "@radix-icons/vue";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useRouter, useRoute } from "vue-router";

const isPasswordVisible = ref(false);
const errorMessage = ref("");
const loading = ref(false);
const email = ref("");
const password = ref("");

const router = useRouter();
const route = useRoute();
const strapi = useStrapiAuth();
const { t } = useI18n();

const disabledButton = computed(() => {
  return !password.value || !email.value;
});

const signUpSuccess = computed(() => {
  return route.query.signUpSuccess;
});

const passwordResetSuccess = computed(() => {
  return route.query.resetPasswordSuccess;
});

const signin = async () => {
  errorMessage.value = "";
  loading.value = true;

  try {
    await strapi.login({
      identifier: email.value,
      password: password.value,
    });

    router.push("/");
  } catch (err) {
    errorMessage.value = t("pages.signin.loginFailed");
    loading.value = false;
  }
};
definePageMeta({
  layout: "landing",
});

useHead({
  title: t("pages.signin.signIn"),
});
</script>
